import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout/MdxLayout.js";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
export const query = graphql`
  query {
    appEmbedImg: file(relativePath: { eq: "appEmbed.png" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Widget not appearing on web store`}</h1>
    <p>{`First, check out our `}<Link className="mdxLayout-link" to='/merchant-hc/sizify-basics/getting-started/' mdxType="Link"><strong parentName="p"><em parentName="strong">{`Getting Started`}</em></strong></Link>{` guide, if you have not already`}</p>
    <p>{`If the widget is still not appearing, here is a list of possible solutions:`}</p>
    <ol>
      <li parentName="ol">{`Ensure Sizify app is toggled to the "On" position`}</li>
    </ol>
    <Img className="mdxLayout-img" fixed={props.data.appEmbedImg.childImageSharp.fixed} mdxType="Img" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Remember, widget only shows up on product pages that are `}<strong parentName="li">{`assigned a size guide`}</strong>{`. Check out `}<Link className="mdxLayout-link" to='/merchant-hc/sizify-basics/getting-started/' mdxType="Link"><strong parentName="li"><em parentName="strong">{`Getting Started`}</em></strong></Link>{` guide.`}</li>
      <li parentName="ol">{`If you are using a `}<strong parentName="li">{`custom theme`}</strong>{` or your current doesn't support auto integration, you may need to set-up widget manually. Check out `}<Link className="mdxLayout-link" to='/merchant-hc/advanced/manually-add-widget-to-my-website/' mdxType="Link"><strong parentName="li"><em parentName="strong">{`Manually install widget`}</em></strong>{`.`}</Link></li>
    </ol>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      